


































import { Component, Prop, Vue } from "vue-property-decorator";
import { BImg, BModal } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperClass, { SwiperOptions } from "swiper";
import ICMImageVisualizer from "../../base/ICMImageVisualizer.vue";

interface SwiperInstance extends Element {
	$swiper: SwiperClass;
}

@Component({ components: { Swiper, SwiperSlide, BImg, BModal, "icm-image-visualizer": ICMImageVisualizer } })
export default class ImageSlider extends Vue {
	@Prop({ default: () => [] }) photos: {
		imageUrl: string;
	}[];

	currentSlideIndex = 0;
	openedImageIndex = 0;

	get mainSwiperOptions(): SwiperOptions {
		return {
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: "#main-next-button",
				prevEl: "#main-prev-button",
			},
			loop: this.photos.length > 1,
			slidesPerColumnFill: "row",
			// speed: 1,
			// autoplay: {
			// 	delay: 2000,
			// 	disableOnInteraction: false,
			// },
			allowTouchMove: false,
			watchOverflow: true,
		};
	}

	previewSwiperOptions: SwiperOptions = {
		slidesPerView: "auto",
		spaceBetween: 20,
	};

	get mainSwiper() {
		return (this.$refs.mainSwiper as SwiperInstance)?.$swiper;
	}

	updateCurrentSlideIndex() {
		this.currentSlideIndex = this.mainSwiper.realIndex;
		this.$emit("slideChanged", this.currentSlideIndex);
	}

	slideTo(index: number) {
		this.currentSlideIndex = index;
		this.mainSwiper.slideToLoop(index);
	}

	setOpenedImageIndex(index: number) {
		this.openedImageIndex = index;
	}
}
