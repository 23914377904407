






































import { Component, Vue } from "vue-property-decorator";
import { BButton, BButtonGroup, BLink, BContainer, BRow, BCol } from "bootstrap-vue";

import ImageSlider from "@/layouts/components/blog/user/ImageSlider.vue";

import BlogPostAppModel from "@/api/blog_post.app.model";
import { BlogPost } from "@/interfaces/blog_post";
import { showAlert } from "@/helpers";
import { inflateEntity } from "@/api/_request";

interface ErrorProps {
	statusCode: number;
}

@Component({ components: { ImageSlider, BButtonGroup, BButton, BLink, BContainer, BRow, BCol } })
export default class BlogPostDetails extends Vue {
	blogPostModel = BlogPostAppModel;
	post: BlogPost = {} as BlogPost;
	currentPhoto: any = null;

	errorMessage = "";

	mounted() {
		this.loadPost();
	}

	async loadPost() {
		this.$store.dispatch("app/showLoading");

		try {
			const post = await this.blogPostModel.get(this.$route.params.id, { join: "mainCategory" });
			this.post = inflateEntity(post) as BlogPost;
			this.currentPhoto = this.post.photos?.[0] ?? null;
		} catch (error) {
			console.error(error);
			this.errorMessage = this.handleErrorMessage((error as ErrorProps).statusCode);
		}

		this.$store.dispatch("app/hideLoading");
	}

	get photosUrls() {
		return this.post.photos?.map(photo => ({ imageUrl: photo.fileUrl })) ?? [];
	}

	updateCurrentPhoto(photoIndex: number) {
		this.currentPhoto = this.post.photos?.[photoIndex] ?? null;
	}

	editDesign() {
		this.$router.push(`/editar-design/${this.currentPhoto?.id}`);
	}

	copyToClipboard() {
		const content = (this.$refs.contentRef as Element)?.textContent ?? "";

		navigator.clipboard.writeText(content);
		showAlert({
			variant: content ? "success" : "warning",
			text: content ? "Texto copiado :)" : "Nada a ser copiado :|",
			title: "",
			icon: content ? "CheckCircleIcon" : "InfoIcon",
		});
	}

	get isMarketingPiece() {
		return this.post?.mainCategory.name === "Peças de Marketing";
	}

	handleErrorMessage(statusCode: string | number) {
		switch (statusCode) {
			case 404:
				return "Publicação não encontrada.";

			default:
				return "Erro ao buscar publicação.";
		}
	}
}
